import Vue from 'vue'
import Vuex from 'vuex'
import MontaNaturaServices from '@/services/MontaNaturaServices'
import IatfServices from '@/services/IatfServices'
import Iatf2Services from '@/services/Iatf2Services'
import Iatf3Services from '@/services/Iatf3Services'
import mixinUtils from '../mixins/mixin-utils'
import DashboardService from '@/services/DashboardServices'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: 'white',
    SidebarBg: '',
    loggedIn: false,
    userLogado: {
      _id: '',
      name: '',
      email: '',
      token: '',
      changed: '',
      admin: false,
      logado: '',
      manager: false
    },
    simulacoes: {},
    totalSimulacoes: {},
    montaNaturalState: {},
    IATFState: {},
    IATF_2State: {},
    IATF_3State: {},
    racasTouros: [],
    filtroSelecionado: {},
    simulacoeFilteredPeriodo: [],
    simulacoeFiltradasApenasData: [],
    estadosExistentes: {},
    tiposSimuacoesSeparadas: {},
    openSideBar: null,
    estadosMarcado: []
  },
  mutations: {
    SET_SIDEBAR_CUSTOM (state, payload) {
      state.openSideBar = payload
    },

    SET_SIDEBAR_DRAWER (state, payload) {
      state.Sidebar_drawer = payload
    },
    SET_CUSTOMIZER_DRAWER (state, payload) {
      state.Customizer_drawer = payload
    },
    SET_SIDEBAR_COLOR (state, payload) {
      state.SidebarColor = payload
    },

    SET_LOGGED_IN (state, value) {
      state.userLogado.logado = value
      state.loggedIn = value
    },

    SET_DATA_SIMULACOES (state, value) {
      Object.assign(state.simulacoes, value)

      const total = []
      const filtered = value
      Object.assign(total, filtered)
      state.totalSimulacoes = total.length
    },

    SET_DATA_MONTANATURAL (state, value) {
      Object.assign(state.montaNaturalState, value)
    },

    SET_DATA_IATF (state, value) {
      Object.assign(state.IATFState, value)
    },
    SET_DATA_IATF_2 (state, value) {
      Object.assign(state.IATF_2State, value)
    },
    SET_DATA_IATF_3 (state, value) {
      Object.assign(state.IATF_3State, value)
    },
    SET_DATA_RACAS_TOURO (state, value) {
      Object.assign(state.racasTouros, value)
    },

    SET_DADOS_FILTRADOS_PERIODO (state, value) {
      state.simulacoeFilteredPeriodo = {}
      Object.assign(state.simulacoeFilteredPeriodo, value)
    },

    SET_DADOS_FILTRADOS_APENAS_DATA (state, value) {
      state.simulacoeFiltradasApenasData = {}
      Object.assign(state.simulacoeFiltradasApenasData, value)
    },

    SET_ESTADO_EXISTENTES (state, value) {
      state.estadosExistentes = []
      Object.assign(state.estadosExistentes, value)
    },

    SET_ESTADO_MARCADO_MAPA (state, value) {
      state.estadosMarcado = value
    },

    SET_DADOS_DO_FILTRADO (state, value) {
      state.filtroSelecionado = {}
      Object.assign(state.filtroSelecionado, value)
    },

    SET_TIPOS_SIMULACOES_SEPARADAS (state, value) {
      state.tiposSimuacoesSeparadas = {}
      Object.assign(state.tiposSimuacoesSeparadas, value)
    }
  },
  actions: {
    async getAllDataSimulacoesApi ({ commit }) {
      try {
        const response = await DashboardService.getDadosAllSimulacoes()
        if (response.status === 200) {
          commit('SET_DATA_SIMULACOES', response.data.data)
        }
        return response
      } catch (error) {
        console.log(error)
        return error
      }
    },

    setDadosMontaNatural ({ commit }, payload) {
      commit('SET_DATA_MONTANATURAL', payload)
    },

    async updateMontaNatural ({ commit }, value) {
      try {
        const response = await MontaNaturaServices.updateMontaNaturalApi(value)
        if (response.status !== 200) {
          return response
        }

        commit('SET_DATA_MONTANATURAL', value)
        return response
      } catch (error) {
        return error
      }
    },

    setDadosIATF ({ commit }, payload) {
      commit('SET_DATA_IATF', payload)
    },

    async updateDadosIATF ({ commit }, value) {
      try {
        const response = await IatfServices.updateIatfApi(value)
        if (response.status === 200) {
          commit('SET_DATA_IATF', value)
        }
        return response
      } catch (error) {
        return mixinUtils.methods.updateError(error.response.data)
      }
    },

    setDadosIATF2 ({ commit }, payload) {
      commit('SET_DATA_IATF_2', payload)
    },

    async updateDadosIATF2 ({ commit }, value) {
      try {
        const response = await Iatf2Services.updateApiIATF2(value)
        if (response.status === 200) {
          commit('SET_DATA_IATF_2', value)
        }
        return response
      } catch (error) {
        return mixinUtils.methods.updateError(error.response.data)
      }
    },
    setDadosIATF3 ({ commit }, payload) {
      commit('SET_DATA_IATF_3', payload)
    },

    async updateDadosIATF3 ({ commit }, value) {
      try {
        const response = await Iatf3Services.updateApiIATF3(value)
        if (response.status === 200) {
          commit('SET_DATA_IATF_3', value)
        }
        return response
      } catch (error) {
        return mixinUtils.methods.updateError(error.response.data)
      }
    }
  },

  getters: {
    getUserLogged: (state) => state.userLogado,

    getDataSimulacoes: (state) => state.simulacoes,

    getTotalSimulacoes: (state) => state.totalSimulacoes,

    getDataMontaNatural: (state) => state.montaNaturalState,

    getDataIatfRT: (state) => state.IATFState,

    getDataIatf_2RT: (state) => state.IATF_2State,

    getDataIatf_3RT: (state) => state.IATF_3State,

    getRacasTouro: (state) => state.racasTouros,

    getSimulacoesFilteredPeriodo: (state) => state.simulacoeFilteredPeriodo,

    getSimulacoesFiltradasApenasData: (state) => state.simulacoeFiltradasApenasData,

    getEstadosExistentes: (state) => state.estadosExistentes,

    // getEstadoFiltrado: (state) => state.estadoFiltrado,

    getDadosFiltro: (state) => state.filtroSelecionado,

    getTiposSimulacoesSeparadas: (state) => state.tiposSimuacoesSeparadas,

    getEstadosMarcados: (state) => state.estadosMarcado
  }
})
