import Vue from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import vuetify from './plugins/vuetify'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import router from './router'
import db from './db'
import store from '@/store/store'
import VueBlobJsonCsv from 'vue-blob-json-csv'
import Notifications from 'vue-notification'
import VueMask from 'v-mask'

Vue.use(VueMask)
Vue.use(VueBlobJsonCsv)
Vue.use(Notifications)
Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  release: process.env.VUE_APP_VERSION.split('-')[0],
  environment: process.env.VUE_APP_STAGE,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', window.location.hostname, /^\//]
    })
  ],
  tracesSampleRate: 1.0
})

Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.prototype.$db = db

new Vue({
  store,
  router,
  vuetify,
  render: function (h) { return h(App) },
  data: {
    trySync: true
  }
}).$mount('#app')
