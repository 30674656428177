import ApiAxios from './ApiAxios'
const URL_IATF = process.env.VUE_APP_URL_IATF
const token = localStorage.getItem('token')

export default {
  async getIatfApi () {
    try {
      const response = await ApiAxios()
        .get(URL_IATF.concat('/find'),
          { headers: { Authorization: 'Bearer ' + token } })
      return response
    } catch (err) {
      console.log(err)
      if (err.response) {
        return err.response
      } else {
        return err
      }
    }
  },

  async updateIatfApi (dados) {
    try {
      const response = await ApiAxios()
        .put(URL_IATF.concat('/update'), dados, {
          headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => response)
        .catch((error) => error.response)
      return response
    } catch (erro) {
      console.log(erro)
      return erro.response
    }
  }
}
