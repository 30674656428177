import axios from 'axios'
import md5 from 'crypto-js/md5'
const api = process.env.VUE_APP_URL_API
export default {
  async enviarPin (email) {
    try {
      return await axios.post(api + '/pin', { email: email }).then(response => {
        localStorage.setItem('email', email)
        return response
      })
    } catch (error) {
      if (error.response) {
        return error.response
      } else {
        return error
      }
    }
  },

  async authenticate (login) {
    try {
      const response = await axios.post(api + '/authenticate', { email: login.email, pin: login.pin })
      const token = response.data.token

      if (response.status !== 200 || token === '') {
        return false
      }
      localStorage.setItem('token', token)
      return this.getUserApi(login, token)
    } catch (error) {
      return error
    }
  },

  async getUserApi (login, token) {
    const user = await axios.get(api + '/user', { headers: { Authorization: 'Bearer ' + token } })

    if (user.status !== 200 || user === undefined) {
      return false
    }
    return this.loginOk(login, token)
  },

  setUserStorage (dados) {
    const user = this.mapedUser(dados)
    return localStorage.setItem('user', JSON.stringify(user))
  },

  getUserStorage () {
    const dados = JSON.parse(localStorage.getItem('user'))
    if (dados == null) return false

    const user = this.mapedUser(dados)
    return user
  },

  async loginOk (login, token) {
    const details = await axios.post(api + '/manager/user',
      { email: login.email }, { headers: { Authorization: 'Bearer ' + token } })
    const UrlPicture = 'https://www.gravatar.com/avatar/' + md5(details.data.email).toString() + '?s=200&d=404'
    if (UrlPicture) {
      let picture = ''
      picture = axios.get(UrlPicture)
      localStorage.setItem('picture', picture)
    }

    if (details.data._id) {
      this.setUserStorage(details.data)
    }

    localStorage.setItem('email', '')
    return details
  },

  // Limpar Storage
  logout () {
    localStorage.removeItem('user')
    localStorage.clear()
  },

  mapedUser (payload) {
    const user = {
      _id: payload._id,
      email: payload.email,
      name: payload.name,
      token: payload.token,
      admin: payload.admin,
      logado: true,
      changed: payload.changed,
      dateExpired: this.expiredStorage(),
      manager: payload.manager
    }

    return user
  },

  expiredStorage () {
    const now = new Date()

    // 1 dia para expirar o token
    const expiry = now.getTime() + 86400000

    return expiry
  }
}
