import ApiAxios from './ApiAxios'
const URL_IATF3 = process.env.VUE_APP_URL_IATF3
const token = localStorage.getItem('token')

export default {
  async getApiIATF3 () {
    try {
      const response = await ApiAxios()
        .get(URL_IATF3 + '/find', { headers: { Authorization: 'Bearer ' + token } })
      return response
    } catch (err) {
      console.log(err)
      if (err.response) {
        return err.response
      } else {
        return err
      }
    }
  },

  async updateApiIATF3 (dados) {
    try {
      const result = await ApiAxios()
        .put(URL_IATF3 + '/update', dados, {
          headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => response)
        .catch((error) => error.response)
      return result
    } catch (erro) {
      console.log(erro)
      return erro
    }
  }
}
