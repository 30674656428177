<template>
  <v-app>
    <router-view />
    <notifications
        group="foo"
        position="top right"
        width="500px"
      />
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  //  UpdateWrapper
  },
  data () {
    return {}
  }
}
</script>

<!-- Styles -->
<style>
@import "./assets/css/style_snackBar.css";
@import "./assets/css/variables.css";
</style>
