import ApiAxios from './ApiAxios'
const URL_SIMULACOES = process.env.VUE_APP_URL_SIMULACOES
export default {
  async getDadosAllSimulacoes () {
    try {
      const token = localStorage.getItem('token')
      const response = await ApiAxios().get(URL_SIMULACOES + '/find', { headers: { Authorization: 'Bearer ' + token } })
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
}
