import ApiAxios from './ApiAxios'
const URL_MONTANATURAL = process.env.VUE_APP_URL_MONTANATURAL
const token = localStorage.getItem('token')

export default {
  async getMontaNaturalApi () {
    try {
      const response = await ApiAxios().get(URL_MONTANATURAL.concat('/find'), {
        headers: { Authorization: 'Bearer ' + token }
      })

      return response
    } catch (err) {
      console.log(err)
      if (err.response) {
        return err.response
      } else {
        return err
      }
    }
  },

  async updateMontaNaturalApi (dados) {
    try {
      const response = await ApiAxios()
        .put(URL_MONTANATURAL.concat('/update'), dados, {
          headers: { Authorization: 'Bearer ' + token }
        })
        .then((response) => response)
        .catch((error) => error.response)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}
